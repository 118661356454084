import React, { useState } from "react";
import { ExcelSheetModal } from "components/modals/ExcelSheetModal";
import { Button } from "@nextui-org/react";

import {
  faBars,
  faChartBar,
  faCut,
  faDatabase,
  faFileExcel,
  faSignOutAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DbImporterModal } from "./modals/DbImporterModal";
import { StatsModal } from "./modals/StatsModal";
interface NavbarProps {
  handleLogout: () => void;
}

const menuItems = [
  { text: "Estadísticas", icon: faChartBar, action: "stats" },
  { text: "Crear hoja de cálculo", icon: faFileExcel, action: "excel" },
  { text: "Importar DB", icon: faDatabase, action: "dbImport" },
  { text: "Cerrar sesión", icon: faSignOutAlt, action: "logout" },
];

export default function Navbar({ handleLogout }: NavbarProps) {
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false);
  const [isExcelModalOpen, setIsExcelModalOpen] = useState(false);
  const [isDbImportModalOpen, setIsDbImportModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleModal = (action: any) => {
    if (action === "stats") {
      setIsStatsModalOpen(true);
    } else if (action === "excel") {
      setIsExcelModalOpen(true);
    } else if (action === "dbImport") {
      setIsDbImportModalOpen(true);
    } else if (action === "logout") {
      handleLogout();
    }
    setIsMenuOpen(false);
  };

  const renderMenuItem = (item: any) => (
    <li key={item.text}>
      <button
        onClick={() => handleModal(item.action)}
        className="text-2xl font-bold tracking-widest text-gray-900 py-4"
      >
        <FontAwesomeIcon icon={item.icon} /> {item.text}
      </button>
    </li>
  );

  const handleOpenModalStats = (
    event?: React.MouseEvent<HTMLButtonElement>
  ) => {
    setIsStatsModalOpen(true);
    setIsMenuOpen(false);
  };

  const handleStatsCloseModal = () => {
    setIsStatsModalOpen(false);
  };

  const handleOpenModalExcel = (
    event?: React.MouseEvent<HTMLButtonElement>
  ) => {
    setIsExcelModalOpen(true);
    setIsMenuOpen(false);
  };

  const handleExcelCloseModal = () => {
    setIsExcelModalOpen(false);
    setIsMenuOpen(false);
  };

  const handleOpenModalDbImport = (
    event?: React.MouseEvent<HTMLButtonElement>
  ) => {
    setIsDbImportModalOpen(true);
    setIsMenuOpen(false);
  };

  const handleCloseModalDbImport = () => {
    setIsDbImportModalOpen(false);
    setIsMenuOpen(false);
  };

  return (
    <header className="text-gray-900 ">
      <nav className="border-b dark:border-gray-900 border-gray-200 container mx-auto flex flex-wrap items-center justify-between">
        <div className="flex items-center">
          <img
            src="/favicon/android-chrome-192x192.png"
            width={40}
            height={40}
            alt="Logo"
            className="h-10 mr-3 rounded-md shadow-sm"
          />
          <span className="font-bold text-xl tracking-tight">
            <FontAwesomeIcon icon={faCut} />
            {" - "}
            Agenda
          </span>
        </div>
        <button
          onClick={toggleMenu}
          className="btn hamburger md:hidden" // md:hidden oculta este botón en pantallas mayores a 'md'
        >
          <FontAwesomeIcon icon={faBars} />
        </button>

        {/* Menú lateral para móviles */}
        <div
          className={`${
            isMenuOpen ? "fixed" : "hidden"
          } inset-0 bg-white/40 backdrop-blur-md shadow-lg z-50 md:hidden`}
        >
          <div className="flex justify-end ">
            <button
              onClick={() => setIsMenuOpen(false)}
              className="text-gray-900 dark:text-gray-100 mr-2 mt-11 h-8 w-8"
              aria-label="Toggle Menu"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <nav className="flex flex-col items-center justify-around mt-20">
            <ul>{menuItems.map(renderMenuItem)}</ul>
          </nav>
        </div>

        {/* Botones visibles en dispositivos más grandes */}
        <div className="hidden md:flex flex-wrap gap-4 items-center">
          <Button
            onClick={handleOpenModalStats}
            color="primary"
            variant="shadow"
            startContent={
              <FontAwesomeIcon icon={faChartBar} className="mr-2" />
            }
          >
            Estadísticas
          </Button>

          <Button
            onClick={handleOpenModalExcel}
            color="primary"
            variant="shadow"
            startContent={
              <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
            }
          >
            Crear hoja de cálculo
          </Button>

          <Button
            onClick={handleOpenModalDbImport}
            color="primary"
            variant="shadow"
            startContent={
              <FontAwesomeIcon icon={faDatabase} className="mr-2" />
            }
          >
            Importar DB
          </Button>

          <Button
            onClick={handleLogout}
            color="danger"
            variant="shadow"
            startContent={
              <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
            }
          >
            Cerrar sesión
          </Button>
        </div>
      </nav>
      <StatsModal isOpen={isStatsModalOpen} onClose={handleStatsCloseModal} />
      <ExcelSheetModal
        isOpen={isExcelModalOpen}
        onClose={handleExcelCloseModal}
      />
      <DbImporterModal
        isOpen={isDbImportModalOpen}
        onClose={handleCloseModalDbImport}
      />
    </header>
  );
}
